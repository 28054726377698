<template>
  <div class="content">
    <div class="headimg flexcenterc">
      <img
        src="@/assets/image/about/headback.png"
        style="height: 100%; width: 100%"
      />
      <div class="headtext flexcenterc">
        <div class="title">让人人拥有数字资产</div>
        <div class="title-en">MAKING DIGITAL ASSETS AVAILABLE TO EVERYONE</div>
      </div>
    </div>
    <div class="enterprisesdesc">
      <div class="title">关于我们</div>
      <div class="en-title">About Us</div>
      <div class="desc">
        <div class="yuhulogo">
          <img src="@/assets/image/about/logo.png" style="width: 100%;
          object-fit:scale-down;"/>
        </div>
        <div style="width: 8%;">
        </div>
        <div class="desc-content">
          <div v-html="pass"></div>
        </div>
      </div>
    </div>
    <div class="spinerrow">
      <div
          id="timelinediv"
          class="test-1"
          style="margin-top: 135px; overflow-y: hidden; height: 634px"
      >
        <div
            @mouseover="overMar"
            @mouseleave="outMar"
            style="
            background-color: #409eff;
            width: 100%;
            height: 620px;
            opacity: 0;
            position: absolute;
          "
        ></div>
<!--        <div style="position: absolute;background-color: red;-->
<!--        right:0;width:40px;top: 310px" @click="dododo">-->
<!--             重新观看-->
<!--        </div>-->
        <img id="rundiv" class="image" :src="scrollImg" />
      </div>
      <div style="display: flex; justify-content: center;">
        <div style="position: absolute;width:75%;">
              <el-row :gutter="0" class="littlebox"
                      justify="center" type="flex">
                <el-col :span="8" class="boxitem" v-for="item in ourProList" :key="item.index">
                  <div class="" style="width: 70%;height: 100%">
                    <el-row type="flex"  align="center"
                            style="width: 100%;height: 40%">
                      <el-col :span="6" style="" class="flexcenterr">
                        <el-image :src="item.imgUrl"/>
                      </el-col>
                      <el-col :span="16" style="display: flex;
                      flex-direction: column;justify-content: center" >
                        <el-col class="title">{{item.title}}</el-col>
                        <el-col class="title-en text" style="margin-top: 0.5vw;color:#969696;">
                          {{item['en-title']}}</el-col>
                      </el-col>
                    </el-row>
                    <el-row class="content" style="height: 60%;">{{item.content}}</el-row>
                  </div>
                </el-col>
              </el-row>
        </div>
      </div>
    </div>

    <div ref="divTable"
         style="margin-top: 322px; width: 100%" class="service flexcenterc">
      <div class="title">行业及技术服务资质</div>
      <div class="title-en">Industry & Technical Service Qualification</div>
      <el-table
          :data="tableDatas"
          style="width: 70%"
          :header-cell-style="getRowClass"
      >
        <el-table-column type="index" :index="computeTableIndex"
                         label="序号"  align="center">
        </el-table-column>
        <el-table-column prop="x" label="" ></el-table-column>
        <el-table-column prop="qualification" label="资质"  width="280">
        </el-table-column>
        <el-table-column prop="x" label="" ></el-table-column>
        <el-table-column prop="x" label="" ></el-table-column>
        <el-table-column prop="date" label="授权日"></el-table-column>
        <el-table-column
            prop="operation"
            label="操作"
            align="center"
        >
          <template slot-scope="scope">
            <el-button @click="handleTableClick(scope.row)" type="text"
              >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
          id="eModal"
          :visible.sync="dialogVisible"
          @closed="handleDialogClose"
          v-if="selectedRow == 9">
        <!-- <span id="modal-body">{{ selectedRow }}</span> -->
        <img :src="selectedPic" style="width: 50%;padding-bottom: 4vw;" @load="imgOnLoad" />
        <img src="@/assets/image/qr/zero.jpg" style="width:50%;" @load="imgOnLoad" />
      </el-dialog>

      <el-dialog
          id="eModal"
          :width="dialogWidth"
          :visible.sync="dialogVisible"
          @closed="handleDialogClose"
          v-else>
        <!-- <span id="modal-body">{{ selectedRow }}</span> -->
        <img :src="selectedPic" :width="imgWidth" @load="imgOnLoad" />
      </el-dialog>

      <!--       <div class="images flexcenterr">
             <div class="heighblock" style="margin-right: 25px"></div>-->
      <!--        <div class="heighblock2" style="margin-right: 25px"></div>-->
      <!--        <div class="flexcenterc">-->
      <!--          <div class="widthblock"></div>-->
      <!--          <div class="widthblock"></div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="images flexcenterr">-->
      <!--        <div class="flexcenterc">-->
      <!--          <div class="widthblock"></div>-->
      <!--          <div class="widthblock"></div>-->
      <!--        </div>-->
      <!--        <div class="heighblock" style="margin-left: 25px"></div>-->
      <!--        <div class="heighblock2" style="margin-left: 25px"></div>-->
      <!--      </div>-->
    </div>

    <!--    <div style="margin-top: 132px;" class="service flexcenterc">-->
    <!--      <div class="title">行业及技术服务资质</div>-->
    <!--      <div class="title-en">Industry and technical service qualification</div>-->
    <!--      <div class="images flexcenterr">-->
    <!--        <div class="lheighblock" style="margin-right: 33px"></div>-->
    <!--        <div class="lheighblock" style="margin-right: 45px"></div>-->
    <!--        <div class="lwidthblock"></div>-->
    <!--      </div>-->
    <!--      <div class="flexcenterr" style="margin-top:25px">-->
    <!--        <div style="width: 678px;height: 274px; background-color: #C56F31;
margin-right: 22px"></div>-->
    <!--        <div style="width: 694px;height: 274px; background-color: #C56F31;"></div>-->
    <!--      </div>-->
    <!--      <div class="images flexcenterr" style="margin-top: 25px">-->
    <!--        <div class="lheighblock" style="margin-right: 33px"></div>-->
    <!--        <div class="lheighblock" style="margin-right: 45px"></div>-->
    <!--        <div class="lwidthblock"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="together" v-bind:style="footstyle">
      <div class="title">合作企业</div>
      <div class="title-en">Cooperating Companies</div>
<!--      <button @click="rebuildx()">123</button>-->
      <div class="flexcenterc">
        <el-carousel
            indicator-position="outside"
            height="228px"
            style="width: 1116px"
        >
          <el-carousel-item v-for="item in businessiconlist" :key="item.index">
            <div>
              <div class="flexcenterr">
                <div class="businessicon">
                  <img :src="item[0]"/>
                </div>
                <div class="businessicon">
                  <img :src="item[1]"/>
                </div>
                <div class="businessicon">
                  <img :src="item[2]"/>
                </div>
                <div class="businessicon" style="margin-right: 0px">
                  <img :src="item[3]"/>
                </div>
              </div>
              <div class="flexcenterr" style="" v-show="item.length > 4">
                <div class="businessicon">
                  <img :src="item[4]"/>
                </div>
                <div class="businessicon">
                  <img :src="item[5]"/>
                </div>
                <div class="businessicon">
                  <img :src="item[6]"/>
                </div>
                <div class="businessicon" style="margin-right: 0px">
                  <img :src="item[7]"/>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      ourProList: [
        {
          title: '零识区块链',
          'en-title': 'Zero Knowledge Blockchain',
          imgUrl: require('@/assets/image/about/Zero knowledge.png'),
          content: ' “区块链+”媒体服务，成立于2016年，是国内最早的区块链自媒体之一，\
                      粉丝数20万+，媒体矩阵覆盖用户过百万，平均阅读量在3000+，合作企业和专家500+。',
        },
        {
          title: '区块链基地成员单位',
          'en-title': 'Blockchain Base Members',
          imgUrl: require('@/assets/image/about/Associations.png'),
          content: '“区块链+”技术孵化，目前已经与AA加速器、上海区块链产业基地、\
          A轮学堂等单位合作，孵化了区块链+零售、灵活用工、数字版权等十几个区块链创新项目。',
        },
        {
          title: '百联联合实验室',
          'en-title': 'Hundred United Laboratory',
          imgUrl: require('@/assets/image/about/Hundred United.png'),
          content: '“区块链+”行业实验室，目前已经在物联网、新零售、'
              + '科技等领域联合打造了区块链+行业实验室。帮助区块链赋能实体产业数字化转型。',
        },
      ],
      footstyle: {
        'background-image': `url(${require('@/assets/image/about/footback.png')})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        width: '100%',
      },
      invid: 0,
      pass: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #4898FF">上海域乎信息技术有限公司</span>'
          + '是一家以区块链技术为核心的高新技术企业，公司2018年通过了工信部区块链系统功能测试。'
          + '发展至今已获得上市公司和国有产业基金的两轮千万级投资，是蚂蚁区块链、BSN和金链盟的生态节点，'
          + '专注于区块链技术与数字资产服务。核心成员来自阿里巴巴、海尔、中国电信等，'
          + '曾参与工信部物联网区块链蓝皮书和区块链技术安全标准编写。',
      businessiconlist: [
        [
          require('@/assets/image/about/friends/06.png'),
          require('@/assets/image/about/friends/07.png'),
          require('@/assets/image/about/friends/08.png'),
          require('@/assets/image/about/friends/10.png'),
          require('@/assets/image/about/friends/11.png'),
          require('@/assets/image/about/friends/12.png'),
          require('@/assets/image/about/friends/13.png'),
          require('@/assets/image/about/friends/16.png'),
        ],
        [
          require('@/assets/image/about/friends/19.png'),
          require('@/assets/image/about/friends/21.png'),
          require('@/assets/image/about/friends/22.png'),
          require('@/assets/image/about/friends/24.png'),
          require('@/assets/image/about/friends/01.jpg'),
          require('@/assets/image/about/friends/17.png'),
          require('@/assets/image/about/friends/18.png'),
          require('@/assets/image/about/friends/chinamobile.jpg'),
        ],
        [
          require('@/assets/image/about/friends/tencentcloud.jpg'),
          require('@/assets/image/about/friends/bsnlogo.png'),

        ],
      ],
      tableData: [
        {
          serial: 2,
          qualification: '2019区块链黑客松最佳创意奖',
          date: '2019-06-25',
          pic: require('@/assets/image/about/qualification/02.jpg'),
        },
        {
          serial: 3,
          qualification: '2019上海最具投资潜力50佳创业企业',
          date: '2019-11',
          pic: require('@/assets/image/about/qualification/03.jpg'),
        },
        {
          serial: 4,
          qualification: 'ARM人工智能生态联盟委员',
          date: '2019-07',
          pic: require('@/assets/image/about/qualification/04.jpg'),
        },
        {
          serial: 5,
          qualification: 'BSN合格开发者',
          date: '2020-06-01',
          pic: require('@/assets/image/about/qualification/05.jpg'),
        },
        {
          serial: 6,
          qualification: 'ISO9001认证证书',
          date: '2020-06-30',
          pic: require('@/assets/image/about/qualification/06.jpg'),
        },
        {
          serial: 7,
          qualification: 'TBI理事成员',
          date: '2020-08',
          pic: require('@/assets/image/about/qualification/07.jpg'),
        },
        {
          serial: 8,
          qualification: '金链盟证书',
          date: '2020-08',
          pic: require('@/assets/image/about/qualification/08.jpg'),
        },
        {
          serial: 9,
          qualification: '零识区块链',
          date: '2016',
          pic: require('@/assets/image/about/qualification/09.jpg'),
        },
        {
          serial: 10,
          qualification: '软件企业证书',
          date: '2020-07-30',
          pic: require('@/assets/image/about/qualification/10.jpg'),
        },
        {
          serial: 11,
          qualification: '高新技术企业证书',
          date: '2019-12-06',
          pic: require('@/assets/image/about/qualification/11.jpg'),
        },
        {
          serial: 12,
          qualification: '区块链基地成员单位',
          date: '2020',
          pic: require('@/assets/image/about/qualification/12.jpg'),
        },
        {
          serial: 13,
          qualification: '中国移动金牌合作伙伴',
          date: '2019-12-06',
          pic: require('@/assets/image/about/qualification/13.jpg'),
        },
        {
          serial: 15,
          qualification: '上海区块链技术协会理事',
          date: '2019-12-21',
          pic: require('@/assets/image/about/qualification/15.jpg'),
        },
        {
          serial: 16,
          qualification: '上海市软件行业协会会员',
          date: '2020-03-01',
          pic: require('@/assets/image/about/qualification/16.jpg'),
        },
        {
          serial: 17,
          qualification: '区块链系统功能测试证书',
          date: '2018-11-29',
          pic: require('@/assets/image/about/qualification/17.jpg'),
        },
        {
          serial: 18,
          qualification: '蚂蚁区块链生态合作伙伴',
          date: '2020-07-10',
          pic: require('@/assets/image/about/qualification/18.jpg'),
        },
        {
          serial: 19,
          qualification: '蚂蚁区块链节点授权证书',
          date: '2020-05-19',
          pic: require('@/assets/image/about/qualification/19.jpg'),
        },
        {
          serial: 21,
          qualification: '上海市人工智能技术协会会员',
          date: '2019-11-19',
          pic: require('@/assets/image/about/qualification/21.jpg'),
        },
        {
          serial: 22,
          qualification: '上海市单用途预付卡协会理事',
          date: '2020-03',
          pic: require('@/assets/image/about/qualification/22.jpg'),
        },
        {
          serial: 23,
          qualification: '域乎百联新零售区块链联合实验室',
          date: '2019',
          pic: require('@/assets/image/about/qualification/23.jpg'),
        },
        {
          serial: 24,
          qualification: '第三届中国区块链开发大赛优秀奖',
          date: '2019-07-19',
          pic: require('@/assets/image/about/qualification/24.jpg'),
        },
      ],
      selectedRow: 1,
      selectedPic: '',
      dialogVisible: false,
      scrollImg: require('@/assets/image/about/timeline.png'),
      imgWidth: '',
      dialogWidth: '',
    };
  },
  mounted() {
    this.rebuildx();
  },
  created() {
  },
  computed: {
    tableDatas() {
      return [...this.tableData].sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    rebuildx() {
      // const businessiconList = document.getElementsByClassName('businessicon');
      // for (let i = 0; i < businessiconList.length; i += 1) {
      //   const nitem = businessiconList[i];
      //   const iw = nitem.childNodes[0].naturalWidth;
      //   const ih = nitem.childNodes[0].naturalHeight;
      //   console.log(`${iw}//${ih}--x--${ih / iw}`);
      //   if ((ih / iw) <= 0.379) {
      //     console.log(`2xxx${nitem.childNodes[0].src}`);
      //     console.log(nitem.childNodes[0]);
      //     nitem.childNodes[0].setAttribute('class', 'abc');
      //     nitem.childNodes[0].setAttribute('style', 'width: 100%;height:auto');
      //   }
      // }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async dododo() {
      clearInterval(this.invid);
      const rundivx = document.getElementById('timelinediv');
      while (rundivx.scrollLeft > 0) {
        rundivx.scrollLeft -= 10;
        // eslint-disable-next-line no-await-in-loop
        await this.sleep(8);
      }
    },
    Mar() {
      return setInterval(this.Marquee, 8);
    },
    Marquee: (speed) => {
      const rundivx = document.getElementById('timelinediv');
      rundivx.scrollLeft += 1;
      if (rundivx.scrollLeft >= rundivx.scrollWidth / 2 - speed) {
        rundivx.scrollLeft = 0;
      }
    },
    overMar() {
      console.log('start');
      this.invid = this.$options.methods.Mar(this);
    },
    outMar() {
      clearInterval(this.invid);
    },
    showimg(u, dom) {
      console.log(dom.target.getAttribute('imgs'));
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return 'background:#EFEFEF;text-align:center;';
      }
      return '';
    },
    handleTableClick(row) {
      console.log(row);
      this.selectedRow = row.serial;
      this.selectedPic = row.pic;
      this.dialogVisible = true;
    },
    computeTableIndex(index) {
      return index + 1;
    },
    imgOnLoad(e) {
      const img = e.target;
      let width = 0;
      if (img.fileSize > 0 || (img.width > 1 && img.height > 1)) {
        if (img.width < this.$refs.divTable.offsetWidth * 0.6) {
          width = img.width + 40;
          this.dialogWidth = `${width}px`;
          this.imgWidth = 'auto';
        } else if (img.width > img.height) {
          this.dialogWidth = '60%';
          this.imgWidth = '100%';
        } else {
          this.dialogWidth = '45%';
          this.imgWidth = '100%';
        }
      }
    },
    handleDialogClose() {
      this.dialogWidth = '';
      this.imgWidth = '';
    },
  },
};
</script>
<style scoped>
.headtext {
  position: absolute;
  top: 35%;
}

.headtext .title {
  font-size: 50px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.headtext .title-en {
  margin-top: 34px;
  font-size: 34px;
  font-family: Source Han Sans CN Light, Source Han Sans CN Light-Light;
  font-weight: 300;
  font-style: italic;
  text-align: right;
  color: #ffffff;
}

.headimg {
  width: 100vw;
  height: 26vw;
  position: relative;
}

.enterprisesdesc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.enterprisesdesc .title {
  margin-top: 127px;
  height: 37px;
  font-size: 40px;
  font-family: 'Noto Sans SC', sans-serif;
  color: #333333;
}

.enterprisesdesc .en-title {
  margin-top: 27px;
  height: 17px;
  font-size: 24px;
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: Normal;
  text-align: center;
  color: #969696;
  line-height: 35px;
}

.flexcenterc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexcenterr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.enterprisesdesc .desc {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 75%;
}

.yuhulogo {
  width: 20%;
}

.desc-content {
  width: 80%;
  display: flex;
  align-items: center;
  font-size: 1.4vw;
  font-family: PingFang SC Light, PingFang SC Light-Light;
  font-weight: 300;
  text-align: left;
  color: #333333;
  line-height: 2.8vw;
}

.spinerrow {
  width: 100%;
  height: 889px;
  background-color: #f7f7f7;
  position: relative;
}

.spinerrow .image {
  margin-top: 135px;
  height: 389px;
}

.littlebox {
  width: 100%;
  height: 450px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 5px;
}

.boxitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.boxitem img{
  width: 80%;
  height: 92%;
}
.boxitem .title {
  font-size: 20px;
}

.boxitem .title .title-en {
  font-size: 16px;
}

.boxitem .content {
  font-size: 16px;
  display: flex;
  font-weight: 300;
  justify-content: center;
  font-family: PingFang SC Light, PingFang SC Light-Light;
  color: #333333;
  text-align: left;
  line-height: 44px;
}

.service .title {
  margin-top: 60px;
  font-size: 40px;
  font-weight: lighter;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  text-align: center;
  color: #333333;
  height: 37px;
}

.service .title-en {
  margin-top: 27px;
  margin-bottom: 84px;
  font-size: 20px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  text-align: center;
  color: #969696;
  line-height: 35px;
}

.service .images {
  width: 80vw;
}

.heighblock {
  width: 420px;
  height: 568px;
  background-color: #c56f31;
  margin-bottom: 25px;
}

.heighblock2 {
  width: 407px;
  height: 568px;
  background-color: #c56f31;
  margin-bottom: 25px;
}

.widthblock {
  width: 520px;
  height: 270px;
  margin-bottom: 28px;
  background-color: #c56f31;
}

.service .lheighblock {
  width: 322px;
  height: 388px;
  background-color: #c56f31;
}

.service .lwidthblock {
  width: 694px;
  height: 388px;
  background-color: #c56f31;
}

.together {
  height: 627px;
  margin-top: 53px;
  display: flex;
  flex-direction: column;
}

.together .title {
  font-size: 40px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: lighter;
  height: 37px;
  text-align: center;
  color: #333333;
  margin-top: 90px;
}

.together .title-en {
  font-size: 20px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  text-align: center;
  color: #969696;
  line-height: 35px;
  margin-top: 27px;
  margin-bottom: 50px;
}

.businessicon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 4px;
  width: 258px;
  height: 98px;
  margin-right: 29px;
  margin-bottom: 30px;
}

.businessicon > img {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border: 0px;
}

.scrollbar {
  width: 30px;
  height: 300px;
  margin: 0 auto;
}

.test-1::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}

.test-1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.test-1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

</style>
